@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.pricing {
  display: grid;
  grid-gap: 24px;
  grid-column: main-start / main-end;
  grid-template-columns: minmax(10px, 1fr);

  @include breakpoints.breakpoint(l) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }
}

.card {
  border-radius: 8px;
  padding: 118px 40px 56px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  &.core {
    background:
            url('/cards/card-corner-top-left-dark.svg') no-repeat 16px 16px,
            url('/cards/card-corner-top-right-dark.svg') no-repeat right 16px top 16px,
            url('/cards/card-corner-bottom-right-dark.svg') no-repeat right 16px bottom 16px,
            url('/cards/card-corner-bottom-left-dark.svg') no-repeat left 16px bottom 16px,
            url('/cards/card-core-top-bg.svg') no-repeat center 66px white;

  }

  &.premium {
    --color-text: white;
    --color-divider: white;
    background:
              url('/cards/card-corner-top-left-light.svg') no-repeat 16px 16px,
              url('/cards/card-corner-top-right-light.svg') no-repeat right 16px top 16px,
              url('/cards/card-corner-bottom-right-light.svg') no-repeat right 16px bottom 16px,
              url('/cards/card-corner-bottom-left-light.svg') no-repeat left 16px bottom 16px,
              url('/cards/card-premium-bg-top.svg') no-repeat center 0,
              url('/cards/card-premium-bg-condensed.jpg') no-repeat center top / cover;

    &.extended {
      background:
              url('/cards/card-corner-top-left-light.svg') no-repeat 16px 16px,
              url('/cards/card-corner-top-right-light.svg') no-repeat right 16px top 16px,
              url('/cards/card-corner-bottom-right-light.svg') no-repeat right 16px bottom 16px,
              url('/cards/card-corner-bottom-left-light.svg') no-repeat left 16px bottom 16px,
              url('/cards/card-premium-bg-top.svg') no-repeat center 0,
              url('/cards/card-premium-bg-ext.png') no-repeat center top / cover;
    }
  }

  &.elite {
    --color-text: white;
    --color-divider: white;
    background:
            url('/cards/card-corner-top-left-light.svg') no-repeat 16px 16px,
            url('/cards/card-corner-top-right-light.svg') no-repeat right 16px top 16px,
            url('/cards/card-corner-bottom-right-light.svg') no-repeat right 16px bottom 16px,
            url('/cards/card-corner-bottom-left-light.svg') no-repeat left 16px bottom 16px,
            url('/cards/card-elite-bg-top-arrows.svg') no-repeat center top,
            url('/cards/card-elite-bg.svg') no-repeat top center / cover #191919;

    &.extended {
      background:
              url('/cards/card-corner-top-left-light.svg') no-repeat 16px 16px,
              url('/cards/card-corner-top-right-light.svg') no-repeat right 16px top 16px,
              url('/cards/card-corner-bottom-right-light.svg') no-repeat right 16px bottom 16px,
              url('/cards/card-corner-bottom-left-light.svg') no-repeat left 16px bottom 16px,
              url('/cards/card-elite-bg-top-arrows.svg') no-repeat center top,
              url('/cards/card-elite-bg-ext.svg') no-repeat top center / cover #191919;
    }
  }

  .copy {
    font-weight: bold;
    margin-top: 24px;
  }

  &.extended .copy {
    @include breakpoints.breakpoint(l) {
        min-height: 72px;
    }
  }
}

.price {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  margin: 24px 0 0;
}

.per {
  font-size: 12px;
  line-height: 16px;
}

.cardDivider {
  background-color: var(--color-text);
  margin-bottom: 0;

  &.light {
    opacity: 0.3;
  }

  &.stretch {
    margin-top: auto;
  }
}

.list {
  --color-divider--light: rgba(25,25,25, 0.3);
  margin-top: 8px;
  margin-bottom: 16px;

  > * {
    justify-content: center!important;

    &:last-child {
      border-bottom: none!important;
    }
  }

  :global(.ef-text-para) {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }

  .premium &,
  .elite & {
    --color-divider--light: rgba(255,255,255, 0.3);
  }
}

.paymentGroup {
  padding: 32px 24px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 400px;
}

.paymentGroupWrapper {
  margin-top: auto;

  .elite & {
    @include breakpoints.breakpoint(l) {
      position: relative;
    }
  }
}


.eliteButton {
  margin-top: 72px;
}
